/*
 * causes.less
 * -----------------------------------------------
*/

/* -------- Recent Causes ---------- */
section .progress-item .progress-bar .percent,
.section .progress-item .progress-bar .percent {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 40px;
    color: #333333;
    font-size: 11px;
    font-weight: 600;
    line-height: 10px;
    padding: 3px 8px;
    position: absolute;
    right: 3px;
    text-align: center;
    top: 2px;
}
section .progress-item .progress,
.section .progress-item .progress{
    background-color: #e1e2e6 ;
    border-radius: 50px ;
    box-shadow: none ;
    height: 20px ;
}

/*
 * 3.6  -> Causes
 * -----------------------------------------------
*/
.causes .thumb {
    position: relative;
    overflow: hidden;
}
.causes .thumb img {
    transition: all 500ms ease 0s;
}
.causes:hover .thumb img {
    transform: scale(1.1);
}
.causes .thumb .overlay-donate-now {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: -50px;
    transition: all 400ms ease 0s;
}
.causes:hover .thumb .overlay-donate-now {
    top: 10%;
}
.causes .thumb .entry-date {
    bottom: 0;
    position: absolute;
    right: -44px;
    .transition(all 0.3s ease 0s);
}
.causes:hover .thumb .entry-date {
    right: 0;
}
.donate-piechart {
    margin: 0 auto;
}
.donate-piechart.piechart-absolute {
    bottom: -40px;
    right: 15px;
    position: absolute;
}
.donate-piechart .piechart-block {
    border-radius: 50%;
}
.donate-piechart .piechart canvas {
    max-height: 70px;
}
.donate-piechart .piechart canvas {
    background-color: @gray-lighter;
    border-radius: 50%;
    max-height: 80px;
}
.donate-piechart .piechart-block .piechart {
    min-height: 80px;
    min-width: 80px;
}
.donate-piechart .piechart-block .percent::after {
    font-size: 12px;
}
.donate-piechart .piechart-block .percent {
    font-size: 18px;
    line-height: 77px;
    position: relative;
}

/*
 * 3.29  -> Pie Charts
 * -----------------------------------------------
*/
.piechart {
    position: relative;
    margin: 0 auto;
    text-align: center;
}
.piechart .percent {
    font-size: 18px;
    z-index: 100;
    line-height: 60px;
}
.piechart .percent:after {
    content: "%";
    font-size: 18px;
}
.piechart canvas {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}
/*
 * 3.29  -> Featured Causes
 * -----------------------------------------------
*/
.project-conditions {
    background: @white-f9 none repeat scroll 0 0;
    padding: 8px;
}
.project-conditions li {
    border-right: 1px solid #d3d3d3;
    color: #808080;
    width: 32%;
}
.project-conditions li:last-child {
    border-right: medium none;
}
.project-conditions li strong {
    display: block;
    font-weight: 600;
    color: @black-222;
}