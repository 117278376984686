/*
 * Shortcode: testimonials.less
 * -----------------------------------------------
*/
.testimonial {
  border: 1px solid #eee;
  border-radius: 15px;
}

/* -------- Testimonial-Style1 ---------- */
.testimonial.style1 {
  text-align: center;
  border: none;
}
.testimonial.style1 .item {
  border: none;
}
.testimonial.style1 .item::before {
  display: none;
}
.testimonial.style1 .quote, .testimonial.style1 .thumb {
  margin-bottom: 30px;
}
.testimonial.style1 .author {
  color: @gray-light;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.testimonial.style1 .title {
  color: @black-777;
  margin-bottom: 20px;
}
.testimonial.style1 .owl-item img {
  display: inline-block;
  width: auto;
  width: 75px;
}
.testimonial.style1 .comment {
  padding: 40px 30px 40px 50px;
  text-align: left;
}
.testimonial.style1 .comment p {
  color: @white-base;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
}
.testimonial.style1 .comment::after {
  bottom: 80px;
  color: @white-base;
  content: "\f10e";
  font-family: fontawesome;
  font-size: 125px;
  opacity: 0.15;
  position: absolute;
  right: 20px;
}

/* -------- Testimonial-Style2 ---------- */
.testimonial.style2 {
  text-align: center;
}
.testimonial.style2 .thumb img {
  display: inline-block;
  width: auto;
  width: 75px;
}
