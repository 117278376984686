 @charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        helpingpro
Version:        3.0
Last change:    08.09.2017
Primary use:    Nonprofit, Crowdfunding & Charity HTML5 Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-set-charity.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Raleway:200,300,400,500,600,700,800|Trirong:100,200,300,400,500,600,700,800,900);

 
// Table of contents
@import "less-helpingpro/table-of-content.less";

 
// Initialize Variables
@import "less-helpingpro/variables.less";
@import "less-helpingpro/mixins.less";
// Typography
@import "less-helpingpro/typography.less";

// Common Styles
@import "less-helpingpro/common.less";
@import "less-helpingpro/extra.less";
@import "less-helpingpro/overlay.less";

// Header
@import "less-helpingpro/header.less";

// Nav
@import "less-helpingpro/nav.less";

// Content Blocks
@import "less-helpingpro/topbar.less";
@import "less-helpingpro/inner-header-title.less";
@import "less-helpingpro/vertical-nav.less";
@import "less-helpingpro/menu-full-page.less";
@import "less-helpingpro/boxed-layout.less";
@import "less-helpingpro/form.less";
@import "less-helpingpro/side-push-panel.less";
@import "less-helpingpro/box-hover-effect.less";
@import "less-helpingpro/gallery-isotope.less";
@import "less-helpingpro/sliders.less";
@import "less-helpingpro/home.less";
@import "less-helpingpro/about.less";
@import "less-helpingpro/causes.less";
@import "less-helpingpro/volunteer.less";
@import "less-helpingpro/contact.less";
@import "less-helpingpro/event.less";
@import "less-helpingpro/practice-area.less";
@import "less-helpingpro/services.less";
@import "less-helpingpro/job.less";
@import "less-helpingpro/shop.less";
@import "less-helpingpro/blog.less";

// Shortcodes
@import "less-helpingpro/shortcodes.less";


// Widgets
@import "less-helpingpro/widgets.less";


// Footer
@import "less-helpingpro/footer.less";